@import '../../../assets/css/var';
@import '../../../assets/css/theme';
.common_modal {
  &_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      flex: 1;
      &:nth-child(2) {
        margin-left: 20px !important;
      }
    }
  }
  &_title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text-default;
    &_left {
      justify-content: flex-start;
    }
  }
}
:global {
  .sun-common-modal.ant-modal .ant-modal-footer {
    margin-top: 30px;
  }
  .sun-common-modal.ant-modal .ant-modal-header {
    margin-bottom: 14px;
  }
  .ant-modal-close-x {
    color: $color-text-default;
  }
}
@include mobile-only {
  :global {
    .sun-common-modal.ant-modal .ant-modal-footer {
      margin-top: 26px;
    }
  }
}

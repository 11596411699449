@import '../../assets/css/theme';
@import '../../assets/css/mixins';
$item_m_bg_light: rgba(255, 255, 255, 0.5);

.community_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  @include mobile-only {
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 26px;
  }

  &_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    opacity: 0.5;
    @include darkThemeOnly {
      background: rgba(255, 255, 255, 0.1);
    }
    @include lightThemeOnly {
      background: rgba(51, 51, 51, 0.1);
    }
    @include mobile-only {
      width: 30px;
      height: 30px;
    }

    text-align: center;
    padding: 0 4px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 30px;
    position: relative;
    @include mobile-only {
      margin-right: 0px;
    }
    &:hover {
      opacity: 1;
      > div {
        opacity: 1;
      }
    }
    &:last-child {
      margin-right: 0;
    }

    img {
      width: 26px;
      @include mobile-only {
        width: 22px;
      }
    }
  }

  &_title {
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $color-text-default;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -25px;
    opacity: 0;
  }
}

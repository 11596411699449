@import './assets/css/var.scss';
@import 'assets/css/theme';
@import 'assets/css/mixins';

@font-face {
  font-family: 'Lato';
  src: url(../src/assets/fonts/Lato-Regular-15.ttf);
}
@font-face {
  font-family: 'Lato';
  font-weight: 500;
  src: url(../src/assets/fonts/Lato-Medium-13.ttf);
}
@font-face {
  font-family: 'Lato';
  font-weight: bold;
  src: url(../src/assets/fonts/Lato-Bold-7.ttf);
}

@font-face {
  font-family: 'AlibabaPuHuiTiH';
  // font-weight: bold;
  src: url(../src/assets/fonts/Alibaba-PuHuiTi-Heavy.otf);
}
@font-face {
  font-family: 'Helvetica';
  font-weight: bold;
}
@font-face {
  font-family: 'HeadLineA Regular';
  src: url(./assets/fonts/HeadLineARegular.woff);
}
html {
  background: $color-bg;
  font-size: 10pt;

  body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 14px;
    font-family: 'PingFangSC-Semibold', 'PingFang SC', 'Lato', 'Hiragino Sans GB', 'Heiti SC', 'Microsoft YaHei',
      'WenQuanYi Micro Hei', sans-serif;

    .modal-btn {
      display: inline-block;
      font-weight: 500;
      color: #4e545a;
      font-family: inherit;
      font-size: inherit;
      background: transparent;
      padding: 0.25em 1.5em;
      position: relative;
      -webkit-transition: all 0.2s ease-in;
      transition: all 0.2s ease-in;
      border: 1px solid #4e545a;
      cursor: pointer;
      border-radius: 4px;
      box-sizing: border-box;
      line-height: 28px;
    }

    a {
      color: #5916e1;
      text-decoration: none;
      &:hover {
        color: #5916e1;
      }
    }

    button:not(.pure-comp) {
      border: none;
      background: #5915e1;
      color: #ffffff;
      padding: 4px 10px;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }
    }

    // antd tab style
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
      border: none;
    }

    .bold {
      font-weight: bold;
    }

    .ant-picker-focused {
      box-shadow: none;
      border-color: #e1e1e1;
    }

    .ant-picker:hover,
    .ant-picker-focused {
      box-shadow: none;
      border-color: #e1e1e1;
    }

    .ant-picker.active {
      border-color: #5916e1;
    }
  }

  // antd input style
  .ant-input-affix-wrapper-disabled {
    background: #fff;
  }

  .ant-input-affix-wrapper-focused:not(.pure-comp) {
    border: 1px solid #5916e1;
    box-shadow: none;
  }

  #root .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled, .pure-comp):hover {
    border-color: #5916e1;
  }

  // antd table style(pure-table 不被项目中的全局样式覆盖)
  .ant-table-wrapper:not(.pure-comp) {
    .ant-table {
      color: #333;
      background: transparent;
      border: none;

      .ant-table-thead > tr {
        & > th {
          color: #999;
          background: #fff;
          border: none;
          font-size: 14px;
          font-weight: normal;
          &:last-child {
            border-right: none;
          }
        }
        & > td:last-child {
          border-right: none;
        }
      }

      .ant-table-tbody {
        > tr:hover:not(.ant-table-expanded-row) > td,
        .ant-table-row-hover,
        .ant-table-row-hover > td {
          background: #fff !important;
        }

        tr td {
          border: none;
          padding: 5px;
          color: #333;
          text-align: left;
        }

        & > tr:last-child > td {
          border-bottom: none;
        }
      }

      table {
        border: none;
        display: flex;
        flex-wrap: wrap;

        thead,
        tbody {
          width: 100%;
        }

        thead {
          border-bottom: 1px solid #f2f2f2;
        }

        tr {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        td,
        th {
          width: 25%;
          text-align: center;
        }
      }

      &.ant-table-empty {
        table tr {
          justify-content: center;
        }
      }
    }
    .ant-table-empty {
      .ant-table-placeholder {
        color: #fff;
      }
    }
  }

  // antd button style
  .ant-btn:not(.pure-comp),
  .ant-btn-primary:not(.pure-comp) {
    background: $color-button-primary;
    border: none;
    color: $white;

    &:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: $color-button-primary;
      color: $white;
    }
  }
  .ant-btn.ant-btn-primary:disabled {
    background: $color-button-primary;
    color: #fff;
    cursor: not-allowed;
    opacity: 0.3;
  }

  .ib-parent div,
  .ib-parent p,
  .ib-parent h1,
  .ib-parent h2,
  .ib-parent h3,
  .ib-parent h4,
  .ib-parent h5,
  .ib-parent h6 {
    display: inline-block;
  }
}

.ant-tooltip-inner {
  font-size: 12px;
}

.fieldset-title {
  font-weight: 700;
  line-height: 16px;
  font-size: 18px;
  position: absolute;
  top: 15px;
  left: 15px;
}

#root {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;

  @include darkThemeOnly {
    background-image: url(./assets/images/base/bg_base_shadow1.png), url(./assets/images/base/bg_base_shadow2.png),
      url(./assets/images/base/bg_base_shadow3.png);
    background-position: left 0 bottom 5%, right 0 bottom 25%, left bottom;
    background-size: 607px auto, 750px auto, 100% auto;
    background-repeat: no-repeat, no-repeat, no-repeat;
    // .base-dark-show {
    //   display: initial;
    // }
    .base-light-show {
      display: none !important;
    }
  }

  @include lightThemeOnly {
    background-image: url(./assets/images/base/bg_base_shadow1_light.png),
      url(./assets/images/base/bg_base_shadow2_light.png), url(./assets/images/base/bg_base_shadow3_light.png);
    background-position: left 0 bottom 5%, right 0 bottom 25%, left bottom;
    background-size: 607px auto, 750px auto, 100% auto;
    background-repeat: no-repeat, no-repeat, no-repeat;
    .base-dark-show {
      display: none !important;
    }
    // .base-light-show {
    //   display: initial;
    // }
  }

  .ant-input:focus {
    box-shadow: none;
  }

  .ant-select-dropdown {
    background: $color-modal-bg;
    .ant-select-item {
      color: $color-text-default;
      border-radius: 0;
      font-weight: 600 !important;
      &:hover {
        background-color: #4d4366ff;
      }
      @include lightThemeOnly {
        &:hover {
          background: rgba(51, 51, 51, 0.1);
        }
      }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      @include lightThemeOnly {
        background: rgba(51, 51, 51, 0.1);
      }
    }
  }

  .ant-select.ant-select-focused {
    box-shadow: none;
    // border: none;
  }

  .ant-select.ant-select-focused:not(.ant-select-focused-disabled):hover {
    box-shadow: none;
    // border: none;
  }

  .ant-select.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
    // border: none;
  }

  .ant-select.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    // border: none;
    box-shadow: none;
  }

  .cp {
    cursor: pointer;
  }

  .main {
    position: relative;
    // padding-bottom: 80px;
  }

  .container {
    // padding-bottom: 50px;
    min-height: calc(100vh - 40px);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .window {
    // background: #f1ebde;
    background: #fff;
    position: relative;
    cursor: default;
    margin: 2em auto;
    width: 90%;
    color: #333;
    padding: 30px 24px;
    border-radius: 15px;
    box-shadow: 0px 20px 40px rgba(148, 148, 148, 0.0606);
    // -webkit-box-shadow: 7px 7px 0px rgba($color: #000, $alpha: 0.3);
    // box-shadow: 7px 7px 0px rgba($color: #000, $alpha: 0.3);
    max-width: min(728px, 90vw);
    // border: 6px double #dacdaf;
    // border-radius: 15px;

    &.pl-0 {
      padding: 30px 0;

      .ant-table table tr {
        padding: 0 24px;
      }
    }

    &.charts {
      min-height: 200px;

      ul.li-inline {
        li {
          display: inline-block;
          list-style: none;
          margin-right: 10px;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }

  legend {
    width: unset;
    max-width: unset;
    margin-bottom: unset;
    padding: unset;
    white-space: unset;

    &.left {
      text-align: left;
    }
  }

  .legend-title {
    line-height: 16px;
    font-size: 18px;
    font-weight: bold;
  }

  .jcst {
    display: flex;
    justify-content: space-between;
  }

  fieldset {
    min-width: unset;
    margin: unset;
    padding: unset;
    // border: 2px groove;
    min-width: 0;
    border-radius: 8px;
    // padding: 0 10px 10px;
  }

  .flex-inline {
    display: inline-flex;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.column {
      flex-direction: column;
      align-items: center;
    }

    &.all-start {
      justify-content: flex-start;
      align-items: flex-start;
    }

    &.all-end {
      justify-content: flex-end;
      align-items: flex-end;
    }

    &.a-end {
      align-items: flex-end;
    }

    &.a-middle {
      align-items: center;
    }

    &.a-start {
      align-items: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.start {
      justify-content: flex-start;
    }

    &.end {
      justify-content: flex-end;
    }

    &.top {
      align-items: flex-start;
    }

    &.around {
      justify-content: space-around;
    }

    &.wrap {
      flex-wrap: wrap;
    }

    &.stretch {
      align-items: stretch;
    }
  }

  .flex-1 {
    flex: 1;
  }

  .center {
    text-align: center;
  }

  .half {
    width: 49.5%;
  }

  .mt-2 {
    margin-top: 2px !important;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-8 {
    margin-top: 8px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-12 {
    margin-top: 12px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .mt-16 {
    margin-top: 16px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-24 {
    margin-top: 24px;
  }

  .mt-28 {
    margin-top: 28px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mt-34 {
    margin-top: 34px;
  }

  .mt-35 {
    margin-top: 35px;
  }

  .mt-36 {
    margin-top: 36px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-12 {
    margin-bottom: 12px;
  }

  .mb-14 {
    margin-bottom: 14px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .ml--6 {
    margin-left: -6px;
  }

  .ml-2 {
    margin-left: 2px;
  }

  .ml-8 {
    margin-left: 8px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .ml-12 {
    margin-left: 12px;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .ml-30 {
    margin-left: 30px;
  }

  .mr-4 {
    margin-right: 4px;
  }

  .mr-5 {
    margin-right: 5px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mr-12 {
    margin-right: 12px;
  }

  .mr-16 {
    margin-right: 16px;
  }

  .mr-80 {
    margin-right: 80px;
  }

  .mt-sp4 .ant-radio {
    margin-top: -6px;
  }

  .w-100 {
    width: 100%;
  }

  .h-100 {
    height: 150px;
  }

  .m-10 {
    margin: 10px;
  }

  .ml-0 {
    margin-left: 0 !important;
  }

  .mr-0 {
    margin-right: 0 !important;
  }

  .lh-24 {
    line-height: 24px !important;
  }

  .fs-12 {
    font-size: 12px !important;
  }

  .fw-bold {
    font-weight: bold !important;
  }

  .padding-0 {
    padding: 0 !important;
  }

  .pb-4 {
    padding-bottom: 4px !important;
  }

  .pb-40 {
    padding-bottom: 40px !important;
  }

  .no-bg {
    background: transparent !important;
  }

  .pool3-img {
    width: 42px;
    height: 31px;
  }

  .red-error {
    background: #f8f7c8;
    color: #333;
    text-align: left;
    border-radius: 5px;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    font-size: 12px;
  }

  .bold {
    font-weight: 700;
  }

  .clickable {
    cursor: pointer;
  }

  .divide {
    margin: 0 5px;
  }

  .hover-underline:hover {
    text-decoration: underline;
  }

  .anticon {
    &.anticon-question-circle {
      > svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .c9 {
    color: #999;
  }

  .c-purple {
    color: #5914e1 !important;
    cursor: pointer;
  }

  .btns {
    a {
      margin: 0 10px;
    }
  }

  .ant-select:not(.pure-comp) {
    width: 104px;
    background: #3782fa;
    color: #333;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: inline-flex;
    align-items: center;

    &.ant-select-single.ant-select-open .ant-select-selection-item {
      color: #333;
    }

    &.ant-select-show-arrow {
      background-color: transparent;
    }

    .ant-select-selector {
      background: #ececef;
      // border: 2px solid #979797;
      border: none;
      height: 40px;
      border-radius: 5px;
      width: 100px;

      .ant-select-selection-item {
        display: flex;
        align-items: center;
        padding-right: 0;
        line-height: 24px;

        > img {
          width: 16px;
          margin: 0;
          margin-right: 5px;
        }
      }
    }
  }
  .w-170 {
    width: 170px;
  }

  .w-190 {
    width: 190px;
  }

  .w-200 {
    width: 200px;
  }

  ul:not(.pure-comp) {
    padding-left: 0;
    margin-bottom: 0;

    li {
      list-style: none;
      word-break: break-all;
      line-height: 22px;
    }
  }

  p {
    margin-bottom: 0;
  }

  input {
    max-width: 100%;
  }

  .ad-radio .ant-input {
    border: none;
    text-align: left;
    height: 38px;

    &.ant-input-disabled {
      border: none;
    }
  }

  .exchange-rate,
  .swap-pool-des {
    @media (max-width: 800px) {
      flex-direction: column;
      align-items: baseline;
    }
    margin: 12px 0;
    // justify-content: flex-end !important;
    font-size: 12px;

    .ant-checkbox-wrapper {
      font-size: 12px;
    }
  }

  .exchange-rate {
    .ant-checkbox-wrapper {
      align-items: center;
    }

    .ant-checkbox {
      top: 0;
    }
  }

  .hide-for-pc {
    @media screen and (max-width: ($mobile-width-breakpoint - 1)) {
      display: none;
    }
  }

  .hide-for-mobile {
    @media screen and (min-width: $mobile-width-breakpoint) {
      display: none;
    }
  }
}

.ant-radio .ant-radio-inner {
  &::after {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    margin-top: 0;
    margin-left: 0;
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
  }
}

.ant-radio-checked .ant-radio-inner {
  border: 2px solid #5915e1;

  &::after {
    background-color: #5914e1;
    transform: scale(1);
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: normal !important;
}

.ant-slider.super-slider {
  background: rgba(193, 160, 255, 0.3);
  border-radius: 4px;
  height: 6px;
  margin: 12px;
  .ant-slider-track {
    background: #722fff;
    border-radius: 4px;
    height: 6px;
    top: 1px;
  }
  .ant-slider-handle {
    color: rgba(140, 81, 255, 1);
    inset-block-start: -2px;
  }
  .ant-slider-handle::after {
    width: 14px;
    height: 14px;
    background-color: rgba(140, 81, 255, 1);
    box-shadow: 0 0 0 3px #fff;
    @include lightThemeOnly {
      box-shadow: 0 0 0 3px #c1a0ff;
    }
  }
  &:hover {
    .ant-slider-track {
      background-color: #722fff;
    }
    .ant-slider-handle::after {
      box-shadow: 0 0 0 3px #fff;
    }
  }
  .ant-slider-handle:hover::after,
  .ant-slider-handle:active::after,
  .ant-slider-handle:focus::after {
    box-shadow: 0 0 0 4px #c1a0ff;
    @include darkThemeOnly {
      box-shadow: 0 0 0 4px #fff;
    }
  }
}

.wallet-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
  text-align: center;
  margin-top: 10px;
}

.ledger-modal-mask,
.ledger-modal-wrap {
  z-index: 1003 !important;
  input {
    appearance: auto;
  }
}

@media screen and (max-width: 800px) {
  #root {
    .ant-select:not(.pure-comp) {
      width: unset;
    }

    .swap input {
      width: 150px;
    }

    .swap .w-190 {
      width: 150px;
    }

    fieldset {
      padding: 0;
    }

    button.sell {
      width: 60%;
    }

    .exchange-rate,
    .swap-pool-des {
      justify-content: flex-start !important;
      align-items: flex-start !important;
    }

    .swap-pool-des {
      margin-top: 0px;
    }

    .window {
      width: calc(100% - 30px);
      padding: 30px 15px 32px;
    }

    .clickable {
      margin-bottom: 6px;
    }
  }
}

.countss,
.countdown {
  height: 60px;
  .cards {
    height: 60px;
    width: 30px;
    &:first-child {
      .side-num {
        text-align: right;
        margin-right: 1px;
      }
    }
  }
  .card,
  .front,
  .back {
    height: 29px;
    width: 30px;
  }
  .fron {
    margin-top: 0;
  }
  .countdown-days .cards:nth-child(1) .card .front .side-num,
  .countdown-hours .cards:nth-child(1) .card .front .side-num,
  .countdown-minutes .cards:nth-child(1) .card .front .side-num,
  .countdown-seconds .cards:nth-child(1) .card .front .side-num {
    text-indent: 2px;
  }

  .countdown-days .cards:nth-child(1) .card .back .side-num,
  .countdown-hours .cards:nth-child(1) .card .back .side-num,
  .countdown-minutes .cards:nth-child(1) .card .back .side-num,
  .countdown-seconds .cards:nth-child(1) .card .back .side-num {
    text-indent: 2px;
  }

  .countdown-days .cards:nth-child(1) .card .front,
  .countdown-hours .cards:nth-child(1) .card .front,
  .countdown-minutes .cards:nth-child(1) .card .front,
  .countdown-seconds .cards:nth-child(1) .card .front,
  .countdown-days .cards:nth-child(2) .card .front,
  .countdown-hours .cards:nth-child(2) .card .front,
  .countdown-minutes .cards:nth-child(2) .card .front,
  .countdown-seconds .cards:nth-child(2) .card .front,
  .countdown-days .cards:nth-child(1) .card .back,
  .countdown-hours .cards:nth-child(1) .card .back,
  .countdown-minutes .cards:nth-child(1) .card .back,
  .countdown-seconds .cards:nth-child(1) .card .back,
  .countdown-days .cards:nth-child(2) .card .back,
  .countdown-hours .cards:nth-child(2) .card .back,
  .countdown-minutes .cards:nth-child(2) .card .back,
  .countdown-seconds .cards:nth-child(2) .card .back {
    background: #722fff;
    border-radius: 0 0 0 0;
  }

  .countdown-days .cards:nth-child(1) .card .front,
  .countdown-hours .cards:nth-child(1) .card .front,
  .countdown-minutes .cards:nth-child(1) .card .front,
  .countdown-seconds .cards:nth-child(1) .card .front {
    border-radius: 10px 0 0 0;
  }

  .countdown-days .cards:nth-child(2) .card .front,
  .countdown-hours .cards:nth-child(2) .card .front,
  .countdown-minutes .cards:nth-child(2) .card .front,
  .countdown-seconds .cards:nth-child(2) .card .front {
    border-radius: 0 10px 0 0;
  }

  .countdown-days .cards:nth-child(1) .card .back,
  .countdown-hours .cards:nth-child(1) .card .back,
  .countdown-minutes .cards:nth-child(1) .card .back,
  .countdown-seconds .cards:nth-child(1) .card .back {
    border-radius: 0 0 0 10px;
  }

  .countdown-days .cards:nth-child(2) .card .back,
  .countdown-hours .cards:nth-child(2) .card .back,
  .countdown-minutes .cards:nth-child(2) .card .back,
  .countdown-seconds .cards:nth-child(2) .card .back {
    border-radius: 0 0 10px 0;
  }

  .side-num {
    height: 161%;
    line-height: 1.6;
    font-size: 36px;
    font-family: 'PingFang SC';
    font-weight: 500;
    // font-size: 36px;
    color: #fff;
    margin-top: 0;
  }
  .sides {
    height: 30px;
  }
  .front .side-num {
    margin-top: -4px;
  }
  .back .side-num {
    margin-top: -34px;
  }
  .front {
    margin-top: 1px;
  }
  .countdown-hours:after,
  .countdown-minutes:after {
    display: none;
  }

  .countdown-hours,
  .countdown-minutes {
    margin-right: 20px;
  }

  .countdown-hours .cards:nth-child(1) .card .back,
  .countdown-hours .cards:nth-child(2) .card .back,
  .countdown-minutes .cards:nth-child(1) .card .back,
  .countdown-minutes .cards:nth-child(2) .card .back,
  .countdown-seconds .cards:nth-child(1) .card .back,
  .countdown-seconds .cards:nth-child(2) .card .back {
    background: #722fff;
  }
  .count-time-left {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
  }
}

.count-down-wrap {
  position: relative;
  margin-top: 16px;
  .countdesc {
    position: absolute;
    bottom: 0;
    z-index: 3;
    width: 100%;
    > span {
      display: inline-block;
      width: 60px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin-right: 20px;
      font-family: 'PingFang SC';
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      transform: scale(0.8);
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.sun-scroll-bar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }
}

.main-page-content {
  margin: 42px auto 0;
  @include common-content-width;
  @include mobile-only {
    margin-top: 14px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1079px) {
  #root {
    // background-image: url();
    background-size: 340px auto, 328px auto, 100% auto;
    background-position: left 0 bottom 35%, right 40%, left bottom;
  }
}

#sunio .ant-notification {
  .ant-notification-notice {
    background: $color-modal-bg;
  }
}

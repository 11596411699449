@import 'theme_font';
//通用字体定义

.sun-title {
  font-family: $font-family-bold;
  &-xl {
    font-size: $font-size-heading-xl;
  }
  &-lg {
    font-size: $font-size-heading-lg;
  }
  &-sm {
    font-size: $font-size-heading-sm;
  }
}

.sun-text {
  &-xl {
    font-family: $font-family-bold;
    font-size: $font-size-xl;
  }
  &-lg {
    font-family: $font-family-bold;
    font-size: $font-size-lg;
    line-height: 22px;
  }
  &-medium {
    font-family: $font-family-default;
    font-size: $font-size-lg;
    line-height: 22px;
  }
  &-secondary-bold {
    font-size: $font-size-secondary;
    font-family: $font-family-bold;
  }
  &-secondary {
    font-size: $font-size-secondary;
    font-family: $font-family-default;
  }
}

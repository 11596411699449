@import './var';

@mixin common-content-width {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @include min-desktop-only {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  @include mobile-only {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@mixin responseFont($fontSize: 14px, $xsFont: 12px, $line-height: 1.4) {
  line-height: $line-height;
  font-size: $fontSize;
  @include mobile-only {
    font-size: $xsFont;
  }
}

@mixin darkThemeOnly {
  html[data-theme='dark'] & {
    @content;
  }
}

@mixin lightThemeOnly {
  html[data-theme='light'] & {
    @content;
  }
}
@function useVar($varName) {
  @return var(--#{$varName});
}

@mixin webpSupport() {
  html[data-webp-lossy='true'] & {
    @content;
  }
}
@mixin webpUnSupport {
  html:not([data-webp-lossy='true']) & {
    @content;
  }
}

//mobile set webp bg-img
// $theme 主题
// $useWebp 是否使用webp
// $img_names 本地图片地址，相对dao/images
@mixin webpSupportBackgroundImgMobile($theme, $useWebp, $img_names...) {
  $m_webp: ();
  $m_png: ();
  @each $img in $img_names {
    @if $useWebp {
      $m_webp: append($m_webp, url('../images/#{$img}.webp'), comma);
      $m_png: append($m_png, url('../images/#{$img}.png'), comma);
    } @else {
      $m_png: append($m_png, url('../images/#{$img}.png'), comma);
    }
  }

  @if $useWebp {
    html[data-webp-lossy='true'][data-theme='#{$theme}'] & {
      @media (max-width: ($mobile-width-breakpoint - 1)) {
        background-image: $m_webp;
      }
    }
    html:not([data-webp-lossy='true'])[data-theme='#{$theme}'] & {
      @media (max-width: ($mobile-width-breakpoint - 1)) {
        background-image: $m_png;
      }
    }
  } @else {
    [data-theme='#{$theme}'] & {
      @media (max-width: ($mobile-width-breakpoint - 1)) {
        background-image: $m_png;
      }
    }
  }
}

// pc set webp bg-img
// $theme 主题
// $useWebp 是否使用webp
// $img_names 本地图片地址，相对dao/images
@mixin webpSupportBackgroundImgPc($theme, $useWebp, $img_names...) {
  $pc_webp: ();
  $pc_png: ();
  @each $img in $img_names {
    @if $useWebp {
      $pc_webp: append($pc_webp, url('../images/#{$img}.webp'), comma);
      $pc_png: append($pc_png, url('../images/#{$img}.png'), comma);
    } @else {
      $pc_png: append($pc_png, url('../images/#{$img}.png'), comma);
    }
  }
  @if $useWebp {
    html[data-webp-lossy='true'][data-theme='#{$theme}'] & {
      @media (min-width: ($mobile-width-breakpoint)) {
        background-image: $pc_webp;
      }
    }
    html:not([data-webp-lossy='true'])[data-theme='#{$theme}'] & {
      @media (min-width: ($mobile-width-breakpoint)) {
        background-image: $pc_png;
      }
    }
  } @else {
    [data-theme='#{$theme}'] & {
      @media (min-width: ($mobile-width-breakpoint)) {
        background-image: $pc_png;
      }
    }
  }
}

@import '../../assets/css/theme';
@import '../../assets/css/mixins';

.ip_modal:global(.ant-modal) {
  :global(.ant-modal-content) {
    padding: 0 30px 40px;
    text-align: left;
  }
  :global(.ant-modal-close) {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  img {
    position: relative;
    margin: -40px auto 0;
    display: block;
    width: 109px;
    height: 100px;
  }
  :global(.access-img-wrap) {
    &::before {
      content: '';
      display: table;
    }
  }
  :global(.access-modal-list) {
    p {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: $color-text-default;
      line-height: 25px;
      margin: 30px 0 0;
      text-align: left;
    }
  }
}

@include mobile-only {
  .ip_modal:global(.ant-modal) {
    :global(.ant-modal-content) {
      padding: 0 20px 40px;
    }

    :global(.access-modal-list) {
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

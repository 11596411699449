//重要标题文字
$font-size-heading-xl: 42px;
// 输入框内容文字、页签式模块已选中标题、首页数据文字、浏览器数据文字
$font-size-heading-lg: 30px;
//模块标题、按钮文字、首页模块内容文字、页签式模块未选中标题、SunCurve菜单文字、浏览器标题文字
$font-size-heading-sm: 20px;

//代币选择文字、模块标题、模块内容、顶部菜单文字
$font-size-xl: 16px;
//表单表头、表单可点击文字、按钮文字、footer标题文字、余额文字、浏览器涨幅数据
$font-size-lg: 14px;

//模块内容说明文字、分页器文字、选择器文字
$font-size-secondary: 12px;

$font-family-bold: PingFangSC-Semibold, PingFang SC, Lato;
$font-family-default: PingFangSC-Regular, PingFang SC, Lato;

@import './theme';
@import './mixins';
.base-footer {
  margin: 0 auto;
  padding: 200px 0 40px;
  width: 100%;
  max-width: min(1200px, 90vw);
  display: flex;
  justify-content: space-between;
  .footer-l {
    display: flex;
    flex-wrap: wrap;
    .footer-list {
      flex: 1;
      margin-right: 70px;
      text-align: left;
      &:last-child {
        margin-right: 0;
      }
      .footer-title {
        white-space: nowrap;
        margin-bottom: 16px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: $color-text-footer-title;
        line-height: 18px;
      }
      ul {
        li {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        a {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 20px;
          white-space: nowrap;
          color: $color-text-footer-title;
          opacity: 0.5;
          transition: opacity 0.1s;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  .footer-r {
    flex: 1;
    .sun-logo {
      display: flex;
      justify-content: flex-end;
      img {
        display: block;
        width: 152px;
      }
    }
  }

  .foot-hide-for-pc {
    display: block;
  }
  .foot-hide-for-mobile {
    display: none;
  }

  .footer-globalOutlined {
    font-size: 18px;
    color: $color-text-default;
    margin-right: 6px;
  }
}

.copyright {
  margin-top: 30px;
  font-size: 12px;
  font-family: Helvetica;
  color: $color-text-default;
  opacity: 0.5;
  line-height: 14px;
  text-align: right;
}

@media screen and (max-width: 992px) {
  .base-footer {
    padding: 60px 15px 64px;
    max-width: 100%;
    .footer-l .footer-list {
      margin-right: 0;
      flex: auto;
      &:nth-child(2n + 1) {
        width: calc(100% - 142px);
      }
      &:nth-child(2n) {
        width: 142px;
      }
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 30px;
      }
    }
    .footer-r {
      .sun-logo {
        justify-content: flex-start;
        img {
          width: auto;
          height: 30px;
        }
      }
    }
    .foot-hide-for-pc {
      display: none;
    }
    .foot-hide-for-mobile {
      display: block;
    }
  }
  .copyright {
    position: absolute;
    bottom: 45px;
    right: 25px;
  }
}

@import '../../assets/css/mixins';

.question_icon {
  font-size: 12px;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  @include darkThemeOnly {
    color: rgba($color: #fff, $alpha: 0.5);
    background: rgba($color: #fff, $alpha: 0.1);
  }
  @include lightThemeOnly {
    color: rgba($color: #333, $alpha: 0.5);
    background: rgba($color: #333, $alpha: 0.1);
  }
}
.question_img {
  width: 14px;
  height: 14px;
}

@import '../../../assets/css/theme';

.arrow {
  display: inline-block;

  &_left {
    transform: rotate(180deg);
  }
  &_right {}
  &_top {
    transform: rotate(-90deg);
  }
  &_bottom {
    transform: rotate(90deg);
  }
}

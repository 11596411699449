@import '../../../assets/css/theme';
a.common_a {
  font-size: 14px;
  font-weight: 600;
  color: $color-a;
  line-height: 20px;
  cursor: pointer;
  &:link,
  &:visited {
    color: $color-a;
  }
  &:hover {
    color: $color-a-hover;
  }
}

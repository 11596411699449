@import '../../../assets/css/theme';

:global(#sunio) .common_btn {
  font-family: Helvetica Bold, PingFangSC-Semibold, PingFang SC, sans-serif;
}

:global(#sunio .common_btn_gradient) {
  position: relative;
  height: 40px;
  line-height: 40px;
  padding: 0 33px;
  text-align: center;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: PingFangSC, PingFang SC, sans-serif;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  background-image: linear-gradient(315deg, #8e59ff, #ba5bff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  &:hover {
    background: linear-gradient(315deg, #7700ff 0%, #8c51ff 100%);
    color: #fff;
  }
  &:hover svg {
    display: none;
  }
}

:global(#sunio .common_btn_gradient_bg) {
  line-height: 36px;
  text-align: center;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: PingFangSC, PingFang SC, sans-serif;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  background-image: linear-gradient(315deg, #7700ff 0%, #8c51ff 100%);
  color: #fff;
  padding: 0 12px;
}

//统一消费主题入口，使用css变量
$color-primary: var(--color-primary);

// 背景色
$color-bg: var(--color-bg);
$m-header-color-bg: var(--m-header-color-bg);
$m-header-color-active-bg: var(--m-header-color-active-bg);
$color-card-bg-default: var(--color-card-bg-default);
$color-card-bg-active: var(--color-card-bg-active);
$color-modal-bg: var(--color-modal-bg);
$color-table-bg: var(--color-table-bg);
$color-incard-table-bg: var(--color-incard-table-bg);
$color-farming-card-bg: var(--color-farming-card-bg);
$color-table-header-bg: var(--color-table-header-bg);
$color-input-bg: var(--color-input-bg);
$color-input-bg-1: var(--color-input-bg-1);
$color-input-in-modal-bg: var(--color-input-in-modal-bg);
$color-select-active: var(--color-select-active);
$color-switch-primary: var(--color-switch-primary);
$color-switch-quaternary: var(--color-switch-text);
$color-picker-bg: var(--color-picker-bg);
$color-info-card-bg: var(--color-info-card-bg);
$color-large-range-card-bg: var(--color-large-range-card-bg);
$color-small-range-card-bg: var(--color-small-range-card-bg);
$color-info-special-card-bg: var(--color-info-special-card-bg);
$color-info-marker: var(--color-info-marker);
$color-normal-card-bg: var(--color-normal-card-bg);
$color-max-btn-bg: var(--color-max-btn-bg);
$color-search-bg: var(--color-search-bg);
$color-home-htx-btn-text: var(--color-home-htx-btn-text);
$color-home-htx-ploy-bg: var(--color-home-htx-ploy-bg);
$color-bg-spotlight: var(--color-bg-spotlight);
$color-bg-footer-select: var(--color-bg-footer-select);
$color-bg-footer-select-hover: var(--color-bg-footer-select-hover);
$color-bg-spin-active: var(--color-bg-spin-active);

// 边框阴影
$color-shadow: var(--color-shadow);
$color-border-primary: var(--color-border-primary);
$color-border-secondary: var(--color-border-secondary);
$color-table-column-title: var(--color-table-column-title);
$color-border-pagination: var(--color-border-pagination);
$color-border-normal-card: var(--color-border-normal-card);
$color-max-button-border: var(--color-max-button-border);

// 按钮
$color-button-primary: var(--color-button-primary);
$color-button-primary-text: var(--color-button-primary-text);
$color-button-btm-nav-primary: var(--color-button-btm-nav-primary);
$color-button-default: var(--color-button-default);
$color-button-default-text: var(--color-button-default-text);
$color-button-link: var(--color-button-link);
$color-button-link-disabled-text: var(--color-button-link-disabled-text);
$color-button-border: var(--color-button-border);
$color-button-text-primary: var(--color-button-text-primary);
$color-max-button-text: var(--color-max-button-text);
$color-text-button-bg: var(--color-text-button-bg);
// 文字链接
$color-a: var(--color-a);
$color-a-hover: var(--color-a-hover);
$color-text-default: var(--color-text-default);
$color-text-default-1: var(--color-text-default-1);
$color-text-default-5: var(--color-text-default-5);
$color-text-default-3: var(--color-text-default-3);
$color-text-default-4: var(--color-text-default-4);
$color-text-default-7: var(--color-text-default-7);
$color-text-default-6: var(--color-text-default-6);
$color-text-default-04: var(--color-text-default-04);
$color-text-default-05: var(--color-text-default-05);
$color-text-default-06: var(--color-text-default-06);
$color-text-default-1: var(--color-text-default-1);
$color-text-table-token: var(--color-text-table-token); // L:#722fff, D:#9482f8
$color-text-footer-title: var(--color-text-footer-title);
$color-a-default: var(--color-a-default);
$color-button-link-text: var(--color-button-link-text);
$color-text-step-primary: var(--color-text-step-primary);
$color-text-step-primary: var(--color-text-step-primary);

// 文字类颜色
$color-safe-text: var(--color-safe-text);
$color-warning-text: var(--color-warning-text);
$color-info-primary-text: var(--color-info-primary-text);

// icon类颜色
$color-icon-primary: var(--color-icon-primary);
$color-icon-warning: var(--color-icon-warning);
$color-icon-warning-1: var(--color-icon-warning-1);
$color-icon-warning-3: var(--color-icon-warning-3);
$color-icon-error: var(--color-icon-error);
$color-icon-success: var(--color-icon-success);

//功能色
$color-warning: var(--color-warning);
$color-error: var(--color-error);

$border-button-bordered: var(--border-button-bordered);

// 模块独有变量
$color-account-card-bg-default: var(--color-account-card-bg-default);
$color-account-card-bg-active: var(--color-account-card-bg-active);
$color-token-checked: var(--color-token-checked);
$color-htx-card-bg-default: var(--color-htx-card-bg-default);
$color-curveindex-box-shadow: var(--color-curveindex-box-shadow);
$color-dao-proposals-card-bg-default: var(--color-dao-proposals-card-bg-default);
$color-dao-proposals-card-bg-active: var(--color-dao-proposals-card-bg-active);

// chart
$color-chart-area-west: var(--color-chart-area-west);
$color-chart-area-east: var(--color-chart-area-east);
$color-xaxis-line: var(--color-xaxis-line);
$color-line-bg: var(--color-line-bg);
$color-brush-area-west: var(--color-brush-area-west);
$color-brush-area-east: var(--color-brush-area-east);
$color-brush-accent: var(--color-brush-accent);

// TODO: 以下未注释变量替换为上面标准化的变量
//背景色
//$color-bg: #1d132e;
//卡片底色
$color-card-active-bg: rgba(54, 45, 74);
//未选中卡片底色
//$color-card-bg: rgba(54, 45, 74, 0.4);
//输入部分底色
//$color-input-bg: #403858;
//展示性数据区域底色
$color-info-bg: $color-info-card-bg;
$color-info-bg-light: rgba(193, 160, 255, 0.3);
//展示性数据区域描边
$color-info-border: rgba(255, 255, 255, 0.2);
// 弹窗底色
//$color-modal-bg: #403858;
// 表格底色
//$color-table-bg: #362d4a;
// 表头底色
//$color-table-header-bg: rgba(37, 31, 50, 0.3);
// 表头底色
//$color-table-header-bg: rgba(37, 31, 50, 0.3);
// input组件背景色
//$color-input-bg: #4d4366;
// 默认文本颜色
//$color-text-default: #fff;

// a连接颜色
//$color-a: var(--color-a);
//$color-a-hover: rgba(#c1a0ff, 0.7);

// max btn color
$max-btn-bg: #251f32;
$max-btn-border: rgba(255, 255, 255, 0.1);

$purple-btn: #722fff;
$light-purple-btn: #decdff;

$warning: #ff8700;
$red: #ec5a27;
$error: #ff496a;

// 下面的没有修改，根据需求修改
// 按钮的蓝色
$blue: #722fff;
$orange: #ff8e18;
$purple: #722fff;
$hoverBlue: #3940b7;
$hoverOrgange: #cc7113;
$hoverPurple: #bdc0f5;
$bgColor: #140736;
$black: #333333, #666666, #999999;
$gray: #fafafa, #dbdbdb;
$green: #16c378;
$red: #e85d54;
$white: #ffffff;
$superPurple: #5816e0;
$font333: #333;
$font666: #666;
$font999: #999;
$bgColor: #140736;

@import './theme';

$max-width-breakpoint: 1200px;
$mobile-width-breakpoint: 1080px;

$max-view-width: 90vw;

@mixin mobile-only {
  @media (max-width: ($mobile-width-breakpoint - 1)) {
    @content;
  }
}
@mixin desktop-only {
  @media (min-width: $mobile-width-breakpoint) {
    @content;
  }
}
@mixin small-desktop-only {
  @media screen and (min-width: ($mobile-width-breakpoint)) and (max-width: (1920px - 1)) {
    @content;
  }
}

@mixin min-desktop-only {
  @media (max-width: $max-width-breakpoint) {
    @content;
  }
}

%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

%layout {
  @media screen and (min-width: 1230px) {
    max-width: 1200px;
    margin: 40px auto;
  }
  @include small-desktop-only {
    max-width: 75em;
    margin: 2.5em auto;
  }
  @include mobile-only {
    margin: 36px 15px 24px;
  }
}

%blur-bg {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(14px);
}

%hide-mobile {
  @media (max-width: ($mobile-width-breakpoint - 1)) {
    display: none;
  }
}
%hide-desktop {
  @media (min-width: $mobile-width-breakpoint) {
    display: none;
  }
}

.page-head {
  @extend %layout;

  .link {
    background-color: rgba(255, 255, 255, 0.1);
    margin: 1rem auto;
    padding: 0.6rem 1rem;
    text-align: center;
    max-width: 160px;
    border-radius: 5px;
    a {
      color: #ded0f9;
      text-decoration: underline;
    }
  }
}

@keyframes animateTitle {
  from {
    background-position: 0%;
  }
  to {
    background-position: 100%;
  }
}

%gradient-title {
  background: linear-gradient(94.96deg, #fff7f7 0.41%, #b1e3ff 59.54%, #f8aaff 109.58%);
  font-size: 72px;
  font-weight: 900;
  line-height: 86px;
  align-items: center;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  background-size: 300%;
  animation: animateTitle 2s infinite alternate;
  @include mobile-only {
    font-size: 42px;
    text-align: center;
  }
}

%dim-content {
  font-size: 18px;
  line-height: 18px;
  margin-top: 18px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
}

.gradient-title {
  @extend %gradient-title;
  text-align: center;
  color: #fff;
  font-size: 40px;
  line-height: 40px;

  @media (max-width: ($mobile-width-breakpoint - 1)) {
    font-size: 32px;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

.dim-content {
  @extend %dim-content;

  @media (max-width: ($mobile-width-breakpoint - 1)) {
    font-size: 14px;
    line-height: 22px;
    margin-top: 4px;
  }
}

.window-for-tabs-pane {
  position: relative;
  cursor: default;
  //margin: 26px auto;
  color: $color-text-default;
  padding: 30px 24px;
  background: #362d4a;
  border-radius: 0 0 15px 15px;
  box-sizing: border-box;
  @include mobile-only {
    padding: 20px 10px;
  }
}

%btn {
  border-radius: 10px;
  width: clamp(100px, 210px, 50%);
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    // background-size: 300%;
    // animation: animateTitle 2s infinite alternate;
  }
}

.purple-btn {
  @extend %btn;
  background: linear-gradient(79.72deg, #4719fd 4.86%, #e64dff 103.07%);
}

.dark-btn {
  @extend %btn;
  background: rgba(233, 223, 255, 0.2);
}

.cur-input {
  background: #403858;
  border-radius: 10px;
  margin-top: 4px;

  input {
    background: #403858;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    line-height: 24px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    padding: 10px;
    width: 100%;

    &:hover,
    &:focus {
      border: none;
    }
    &::placeholder {
      color: $white;
      opacity: 0.3;
    }
  }
  .max-btn {
    background: $max-btn-bg;
    border-radius: 15px;
    padding: 4px 14px;
    cursor: pointer;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 17px;
    color: $white;
    margin-right: 10px;
    white-space: nowrap;
  }
}

@import '../../../assets/css/theme';
@import '../../../assets/css/mixins';
.trans_notify {
  display: flex;
  justify-content: flex-start;

  > img {
    width: 115px;
    height: 110px;
    @include mobile-only {
      width: 80px;
      height: 77px;
    }
  }

  .trans_notify_right {
    margin-left: 22px;
    @include mobile-only {
      margin-left: 10px;
    }
  }

  .typo_text_link,
  .trans_title {
    white-space: normal;
  }

  .trans_title {
    font-size: 20px;
    font-weight: 600;
    color: $color-text-default;
    line-height: 28px;
  }

  .trans_massage {
    font-size: 15px;
    color: $color-text-default;
    line-height: 21px;
    margin-top: 8px;
    word-break: break-all;
  }

  .trans_link {
    margin: 22px 0 0 0;
    img {
      margin-left: 7px;
    }
  }
}
